/** @jsx jsx */
import { jsx } from "theme-ui"
import { Image, Divider, Card } from "theme-ui"

const Testimonials = ({ data }) => (
  <Card>
  <div className="testimonials" sx={{
    bg: 'secondaryBg'
  }}>
    <p className="title">What My Client Say</p>
    <h2 className="subtitle">Testimonials</h2>
    <Divider />
    <div className="grids col-1 sm-2">
      {data.map(testimonial => (
        <div className="single-testimonial" > 
          <div className="image">
            <Image src={testimonial.image.publicURL} />
          </div>
          <div className="content">
            <p>{testimonial.content}</p>
            <span>
              <b>{testimonial.name}</b>, {testimonial.role}
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
  </Card>
)

export default Testimonials
