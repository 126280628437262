/** @jsx jsx */
import { jsx } from "theme-ui"
import { Image, Divider } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"

const CTA = ({ data }) => (
  <div className="cta">
      <div className="grids col-1 sm-2">
        <div>
          <h2>{data.title}</h2>
          <p>{data.text}</p>
          <Link
            to="/contact"
            className="button"
            sx={{
              variant: "links.button",
            }}
          >
            {data.link}
            <span class="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
        </div>
        <div>
          <Image src={data.image.publicURL} alt="call-to-action" />
        </div>
      </div>
  </div>
)

export default CTA
