/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import RecentWorkHome from "../components/recent-work-home"
import FAQs from "../components/faqs"
import CTA from "../components/cta"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 480
              maxHeight: 380
              quality: 80
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cta {
          text
          title
          link
          image {
            publicURL
          }
        }
        services {
          title
          subtitle
          content
          image {
            publicURL
          }
          linkText
          linkUrl
        }
        faqs {
          question
          answer
        }
        testimonials {
          name
          role
          content
          image {
            publicURL
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""

  return (
    <Layout>
      <SEO />
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1 className="title">{frontmatter.title}</h1>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {frontmatter.tagline}
          </p>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div>
          {Image ? (
            <Img
              fluid={Image}
              alt={frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Services data={frontmatter.services} />
      <Testimonials data={frontmatter.testimonials} />
      <RecentWorkHome />
      <BlogListHome />
      <FAQs data={frontmatter.faqs} />
      <CTA data={frontmatter.cta} />
    </Layout>
  )
}

export default HomePage
