/** @jsx jsx */
import { jsx } from "theme-ui"
import { Image, Divider } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"

const Services = ({ data }) => (
  <div className="services">
    <p className="subtitle">What I do</p>
    <h2 className="title">My Services</h2>
    <Divider />
    {data.map(service => (
      <div className="grids col-1 sm-2 service">
        <div>
          <h2>{service.title}</h2>
          <h5>{service.subtitle}</h5>
          <p>{service.content}</p>
          <Link
            to={service.linkUrl}
            className="button"
            sx={{
              variant: "links.button",
            }}
          >
            {service.linkText}
            <span class="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
        </div>
        <div className="image">
          <Image src={service.image.publicURL} />
        </div>
      </div>
    ))}
  </div>
)

export default Services
