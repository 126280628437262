/** @jsx jsx */
import { motion, AnimatePresence } from "framer-motion"
import { jsx } from "theme-ui"
import { useState } from "react"
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi"

const FAQs = ({ data }) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null)

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null)
    else setActiveQuestionIndex(questionIndex)
  }

  return (
    <div>
      <h2 className="title">Want To Know More?</h2>
      {data.map((faq, index) => (
        <motion.article
          className="faq-card"
          sx={{
            bg: "cardBg",
          }}
          key={index}
        >
          <div class="post-content">
            <h2
              className="question"
              initial={false}
              onClick={() => {
                toggleQuestion(index)
              }}
            >
              {faq.question}{" "}
              {activeQuestionIndex === index ? (
                <HiOutlineChevronUp />
              ) : (
                <HiOutlineChevronDown />
              )}
            </h2>
            <AnimatePresence initial={false}>
              {activeQuestionIndex === index && (
                <motion.p
                  key={`${faq.question}-${index}`}
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                  className="answer"
                  sx={{
                    color: "muted",
                  }}
                >
                  <time>{faq.answer}</time>
                </motion.p>
              )}
            </AnimatePresence>
          </div>
        </motion.article>
      ))}
    </div>
  )
}

export default FAQs
